import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Slider.css';

import LightingSolutions1 from '../../Assets/SliderImage/LightingSolutions1.jpg'
import LightingSolutions2 from '../../Assets/SliderImage/LightingSolutions2.jpg'
import LightingSolutions3 from '../../Assets/SliderImage/LightingSolutions3.jpg'
import LightingSolutions4 from '../../Assets/SliderImage/LightingSolutions4.jpg'
import LightingSolutions5 from '../../Assets/SliderImage/LightingSolutions5.jpg'




// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Slider = () => {
  return (
    <div className='w-[100]'><Swiper
    spaceBetween={30}
    centeredSlides={true}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: false,
    }}
    navigation={false}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    <SwiperSlide><img src={LightingSolutions1} alt="Lighting Solution Work" className='sm:w-[250px]'/></SwiperSlide>
    <SwiperSlide><img src={LightingSolutions2} alt="Lighting Solution Work" className='sm:w-[250px]'/></SwiperSlide>
    <SwiperSlide><img src={LightingSolutions3} alt="Lighting Solution Work" className='sm:w-[250px]'/></SwiperSlide>
    <SwiperSlide><img src={LightingSolutions4} alt="Lighting Solution Work" className='sm:w-[250px]'/></SwiperSlide>
    <SwiperSlide><img src={LightingSolutions5} alt="Lighting Solution Work" className='sm:w-[250px]'/></SwiperSlide>
    
    
  </Swiper></div>
  );
};

export default Slider;
