import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();
  const resetFormFields = () => {
    form.current.reset(); // Reset the form fields to their initial values
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        "service_k9wx19a",
        "template_xj31z8a",
        form.current,
        "mJsdTBrxW-_UrNtw6"
      );
      resetFormFields();
      console.log(result.text);
    } catch (error) {
      console.error(error.text);
    }
  };
  return (
    <>
      <section className="w-full">
        <div className="p-4  mx-auto  border-2 bg-black border-white rounded-3xl">
          <h2 className=" text-4xl tracking-tight font-extrabold text-center text-[#fab702]"></h2>

          <form
            className="space-y-8"
            id="contact"
            ref={form}
            onSubmit={sendEmail}
          >
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium "
              >
                Your email
              </label>
              <input
                type="email"
                name="user_email"
                id="email"
                className="shadow-sm p-3 bg-gray-50 border border-gray-300 w-full text-sm text-gray-900  rounded-lg border border-gray-300"
                placeholder="name@mail.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium"
              >
                Name
              </label>
              <input
                type="text"
                id="subject"
                name="user_name"
                className="block p-3 w-full text-sm text-gray-900  rounded-lg border border-gray-300 "
                placeholder="Let us know how we can help you"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium "
              >
                Your message
              </label>
              <textarea
                id="message"
                name="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300"
                placeholder="Leave a comment"
              ></textarea>
            </div>
            <button
              type="submit"
              value="Send"
              className="py-3 px-5 text-sm font-medium text-center text-black rounded-3xl bg-[#fab702] sm:w-fit  focus:ring-4 focus:outline-none focus:ring-primary-300  dark:focus:ring-primary-800"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default ContactForm;
