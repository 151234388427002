import React from "react";
// import PlaceHolder_image from "../../Assets/Images/PlaceHolder_image.jpg";
// import { AiOutlineVerticalAlignBottom } from "react-icons/ai";
import Principle_Designer from "../../Assets/Images/Principle_Designer.jpg";
import Slider from "./Slider";
import './Slider.css'

function HomePage() {
  return (
    <>
      <div
        className=" justify-center items-center text-center flex md:m-0 mt-12 w-full"
        id="home"
      >
        <div className="md:relative flex flex-col lg:flex-row justify-center items-center xl:w-[1300px] lg:w-[950px] lg:h-[550px] md:w-[80%] text-center px-4">
          {/*---------------------- LEFT SIDE------------------------------ */}
          <div className="basis-1/2 p-4 static flex justify-center item-center text-center">
            <div className=" py-4 lg:p-0">
              <h1 className="lg:text-6xl md:text-4xl font-bold text-2xl text-[#fab702]">
                Lights Designing
                <br /> Consultant and Solutions.
              </h1>

              <div>
                <h1>Servicing since 2007</h1>
              </div>
            </div>
          </div>

          {/* //-----------------------RIGHT SIDE----------------------------------- // */}
          <div className="basis-1/2 static  ">
            <div className=" flex justify-center rounded-3xl image-slider-box">
              <div className="  slider-section border-4 border-#fab702 flex text-center justify-center rounded-3xl  md:w-[500px] sm:w-[500px]  -z-1 ">
                <Slider />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center py-4">
          <button className="border-2 rounded-xl flex gap-1 p-3 text-2xl text-white hover:border-[#fab702] hover:bg-[#fab702]">
            Read More
            <AiOutlineVerticalAlignBottom className="text-4xl font-bold" />
          </button>
        </div> */}
      </div>
      {/* ---------------Section-Principle-Designer-------------------- */}
      <div className="hidden md:block p-2" id="about" >
        <div className="rounded-3xl flex flex-col justify-center items-center py-8 w-full text-center bg-[#fff]">
          <p className="text-[#fab702] font-bold text-4xl p-6 border-b-2 border-[#fab702] ">
            Principle Designer
          </p>
          <div className=" flex  bg-[#e9e9e9d5] xl:w-[950px] lg:w-[750px]  md:w-[550px] lg:h-[450px] rounded-3xl">
            <div className="basis-1/2 flex-col items-center ">
              <ul className="p-8 text-left text-black">
                <li className=" text-2xl">Mr. Sukhwinder Singh</li>
                <li className="">Electrical amd Lighting Designer</li>
                <li className="">
                  Educational Qualification : B.E.(Electrical)
                </li>
                <li className="">Experience : 16 years.</li>
                <li className=" py-6 italic">
                  "Lighting plays a crucial role in shaping the ambiance of a
                  room. With the myriad of innovative lighting techniques
                  available, designers must exercise caution to avoid excessive
                  illumination. Even a minor oversight in design has the
                  potential to undermine the intended impact of the lighting
                  arrangement."
                </li>
              </ul>

              <div></div>
            </div>
            <div
              className="basis-1/2 relative p-2 right-[-4rem] flex items-center 
          
          "
            >
              <div className="">
                <img
                  src={Principle_Designer}
                  alt="place-holder"
                  className="relative border-2 rounded-3xl "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden bg-[#000] p-2" id="about2">
        <section className="rounded-3xl">
          <section className="flex flex-row justify-center items-center w-full text-center"></section>
          <section className="flex flex-col md:flex-row justify-center items-center p-8 text-center md:text-left">
            <p className="text-[#fab702] font-bold text-4xl pt-6 border-b-2 border-[#fab702]">
              Princple Designer
            </p>
            <div className="bg-[#e9e9e979] rounded-3xl">
              {" "}
              <div className="p-8">
                <img
                  src={Principle_Designer}
                  alt="principle-designer"
                  className="border-2 rounded-3xl w-full"
                />
              </div>
              <div className="md:w-1/2 md:pl-8 py-4  ">
                <ul className="text-white">
                  <li className="text-2xl">Mr. Sukhwinder Singh</li>
                  <li>Electrical and Lighting Designer</li>
                  <li>Educational Qualification: B.E.(Electrical)</li>
                  <li>Experience: 16 years.</li>
                </ul>
                <p className="py-6 italic ">
                  Thank you for your interest in Lighting Solutions. If you have
                  any inquiries or require further information about our
                  specialized Electrical and Lighting Design & Consultancy
                  services, please feel free to get in touch with us.
                </p>
              </div>
            </div>
          </section>
        </section>
      </div>
    </>
  );
}

export default HomePage;
