// import Footer from "./Pages/Footer/Footer";
import NavBar from "./Pages/NavBar/NavBar";
import HomePage from "./Pages/Home/HomePage";
import ServicesPage from './Pages/ServicesPage/ServicesPage';
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import FooterBottom from "./Pages/Footer/FooterBottom";
import Slider from "./Pages/Home/Slider";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <HomePage />
      <About/>
      <ServicesPage />
      <Contact/>
      <FooterBottom/>
    </div>
  );
}

export default App;
