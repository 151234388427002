import React from "react";
// import PlaceHolder_image from "../../Assets/Images/PlaceHolder_image.jpg";
import township from "../../Assets/Images/township.jpg";
import hospital_lighting from "../../Assets/Images/hospital_lighting.jpg";
import Residential_lighting from "../../Assets/Images/Residential_lighting.jpg";
import lighting_controls from "../../Assets/Images/lighting_controls.jpg";

function ServicesPage() {
  return (
    <>
      <div className=" h-full w-full p-2 " id="services">
        <div className="rounded-3xl bg-[#fff]">
          <div className=" flex text-center flex-col items-center static p-8 ">
            <h1 className="py-4 font-bold  border-b-2 border-[#fab702]  text-[#fab702] text-4xl">
              Our Services
            </h1>
            <p className="text-2xl py-4 text-black">
              At Lighting Solutions, we take immense pride in providing an
              extensive array of specialized services within the realm of
              Electrical and Lighting Design & Consultancy.
            </p>
            <span className=" m-0 auto rounded-3xl p-8 text-black xl:w-[1000px] lg:w-[950px]  md:w-[750px]">
              <div className="text-center">
                <section className=" py-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 item-center text-black hover:cursor-pointer">
                  <section className="hover:border-[#fab702] bg-[#e9e9e9d5]  rounded-3xl p-3">
                    <div className="flex justify-center flex-col">
                      <grid className="font-bold ">Lighting Designs</grid>{" "}
                      <img src={township} className="p-3 rounded-3xl" />
                      <p>
                        We provide Complete interior Lighting designs, Facade
                        Lighting and Landscape lighting for Residential,
                        Resorts, Banquets, Restaurant, Commercial Buildings.
                      </p>
                    </div>
                  </section>
                  <section className=" hover:border-[#fab702] bg-[#e9e9e9d5] rounded-3xl p-3">
                    <div className="flex justify-center flex-col">
                      <grid className="font-bold ">Electrical Consultancy</grid>
                      <img
                        src={lighting_controls}
                        className="p-3 rounded-3xl"
                      />
                      <p>
                        We Design electrical services for Residential,
                        Commercial Buildings, Hotels, Institutes, many more.In
                        this, we provide electrical layout drawings showing
                        different services of high and low voltage, wiring
                        details, cable size calculations and routing plans, load
                        calculations, SLDs for panels.
                      </p>
                    </div>
                  </section>
                  <section className=" hover:border-[#fab702] bg-[#e9e9e9d5]  rounded-3xl p-3">
                    <div className="flex justify-center flex-col">
                      <grid className="font-bold ">Audio & Video</grid>
                      <img
                        src={hospital_lighting}
                        className="p-3 rounded-3xl"
                      />
                      <p>
                        We offers wide range of Audio & Video systems for
                        background music, PA systems, Home theaters, Auditoriums
                        for Residential, Commercial Buildings, Hotels,
                        Educational institutes, many more.
                      </p>
                    </div>
                  </section>
                  <section className=" hover:border-[r#fab702] bg-[#e9e9e9d5] rounded-3xl p-3">
                    <div className="flex justify-center flex-col">
                      <grid className="font-bold ">Home Automation</grid>
                      <img
                        src={Residential_lighting}
                        className="p-3 rounded-3xl"
                      />
                      <p>
                        We design and plan lighting controls(mood lighting),
                        curtain controls, automated gates and doors, centralized
                        music controls, Security systems. We can plan individual
                        controls from individual app or integrates all in one
                        app(offline or online).
                      </p>
                    </div>
                  </section>
                </section>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesPage;
