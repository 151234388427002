import React from "react";

const About = () => {
  return (
    <>
      {" "}
      <div className=" h-full w-full  bg-[#000]">
        <div>
          <div className="flex text-center flex-col items-center static p-8 ">
            <h1 className="py-4 font-bold  border-b-2 border-[#fab702] text-4xl text-[#fab702]">
              About Us
            </h1>
            <span className=" m-0 auto  bg-white rounded-3xl p-8 text-black xl:w-[950px] lg:w-[750px]  md:w-[650px] ">
              <p className="text-2xl py-2">
                Established in the year 2007, Lighting Solutions specializes in
                the realm of Electrical and Lighting Design & Consultancy,
                offering comprehensive and innovative electrical and lighting
                design solutions and concepts across diverse application
                domains. These encompass prestigious sectors such as
                Hospitality, Healthcare Facilities, Commercial Complexes &
                Shopping Centers, Retail Establishments, Illumination of
                Architectural and Cultural Landmarks, Residential Complexes,
                Urban Street Lighting, and Aesthetic Landscape Illumination.
                With a global presence, Lighting Solutions presents an
                impressive portfolio of cutting-edge lighting concepts that have
                left an indelible mark on the lives of countless individuals.
                <br />
                Our team at Lighting Solutions Designs is committed to
                delivering unparalleled lighting solutions tailored to the
                unique requirements of each application sector. Our profound
                expertise extends across all facets of lighting, encompassing
                Lighting Control Systems, Lighting Management Systems, and their
                intricate applications.
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
