import React, { useState } from "react";
import logo_lighting_solutions from "../../Assets/Images/logo_lighting_solutions.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import "../../index.css";
const NavBar = () => {
  const [nav, setNav] = useState(false);
  const barClick = () => {
    setNav(!nav);
  };
  return (
    <div className="flex justify-center items-center w-full px-4 bg-[#00000094] z-10 sticky top-0 ">
      <div className="logo-ls">
        <img
          src={logo_lighting_solutions}
          alt="lighting Solutions"
          className="w-[250px]"
        />
      </div>
      <div>
        <ul className="text-2xl justify-center items-center py-5 hidden md:flex ">
          <li>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact
            </Link>
          </li>
        </ul>
        <div
          onClick={barClick}
          className="mobile_bar_icon md:hidden  overflow-visible sticky "
        >
          {!nav ? <FaBars /> : <FaTimes className="z-10 sticky" />}
        </div>
        <div
          className={
            !nav
              ? "hidden"
              : "absolute bg-[#050505d0] top-10 left-0 w-full h-screen  flex flex-col justify-center items-center"
          }
          onClick={barClick}
        >
          {" "}
          <ul className="text-2xl" onClick={barClick}>
          <li>
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="about2"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="contact2"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact
            </Link>
          </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
