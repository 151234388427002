import React from "react";
import PlaceHolder_image from "../../Assets/Images/PlaceHolder_image.jpg";
import ContactForm from "./ContactForm";
const Contact = () => {
  return (
    <>
      <div className="hidden md:block" >
        <div className="flex flex-col justify-center items-center py-8 w-full text-center bg-[#000]">
          <p
            className="text-[#fab702] font-bold text-4xl p-6 border-b-2 border-[#fab702]"
            
          >
            Contact Us
          </p>
          <div className=" flex  bg-[#e9e9e979] xl:w-[950px] lg:w-[750px]  md:w-[750px] xl:h-[600px]  rounded-3xl p-2" id="contact">
            <div className="basis-1/2 flex-col items-center order-2 border-2 rounded-3xl">
              <ul className="p-4 text-left text-white">
                <p className=" text-xl py-2">
                  We value your interest and look forward to connecting with
                  you. Should you have any inquiries, require further
                  information about our services, or wish to discuss a potential
                  collaboration, please feel free to reach out to us using the
                  contact details provided below. Our dedicated team is readily
                  available to assist you and provide tailored solutions to meet
                  your specific needs.
                </p>
                <li className=" text-2xl border-b-2 pb-2">
                  Contact Information
                </li>
                <li className="">
                  231-D, BRS Nagar, Opposite Baba Ishar Singh Sen Sec. School,
                  Ludhiana
                </li>
                <li className="">Office : +91-161-4411779</li>
                <li className="">Mobile : +91-977909779</li>
                <li className="">Email : Design@lightingsolutions.in</li>
              </ul>

              <div></div>
            </div>
            <div
              className="basis-1/2 relative md:left-[-1rem] lg:left-[-4rem] flex items-center order-1
          
          "
            >
              <ContactForm className="relative border-2 rounded-3xl" />
            </div>
          </div>
        </div>
      </div>

      {/* -----------------------------------Mobile-Contact-Section----------------------------------------- */}

      <div className="md:hidden">
        <div
          className="flex flex-col justify-center items-center py-8 w-full text-center bg-[#000] p-8 "
          id="contact2"
        >
          <p className="text-[#fab702] font-bold text-4xl p-8 border-b-2 border-[#fab702]">
            Contact Us
          </p>
          <div className=" flex-row  bg-[#e9e9e9d5]rounded-3xl p-2">
            <div className=" items-center  border-2 rounded-3xl">
              <ul className="p-10 text-left text-white">
                <p className=" text-xl py-2">
                  We value your interest and look forward to connecting with
                  you. Should you have any inquiries, require further
                  information about our services, or wish to discuss a potential
                  collaboration, please feel free to reach out to us using the
                  contact details provided below. Our dedicated team is readily
                  available to assist you and provide tailored solutions to meet
                  your specific needs.
                </p>
                <li className=" text-2xl border-b-2 pb-2">
                  Contact Information
                </li>
                <li className="">
                  231-D, BRS Nagar, Opposite Baba Ishar Singh Sen Sec. School,
                  Ludhiana
                </li>
                <li className="">Office : +91-161-4411779</li>
                <li className="">Mobile : +91-977909779</li>
                <li className="">Email : Design@lightingsolutions.in</li>
              </ul>

              <div></div>
            </div>
            <div
              className=" flex items-center py-8 text-center"
            >
              <ContactForm className="relative border-2 rounded-3xl" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
